import React, { useState } from 'react';
import SneakerSeekerLogo from "../assets/images/logo.svg";
import { Link } from "react-router-dom";

const Navigation: React.FC<{}> = ({}) => {
  const [isActive, setState] = useState<boolean>(false);
  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <a className="navbar-item" href="/">
          <img src={SneakerSeekerLogo} alt="Sneaker Seeker" height="28"/>
        </a>

        <a role="button"
           onClick={() => setState(!isActive)}
           className={`navbar-burger burger ${isActive ? 'is-active' : ''}`} aria-label="menu" aria-expanded="false" data-target="nav-menu-test">
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </a>
      </div>

      <div id={"nav-menu-test"} className={`navbar-menu ${isActive ? 'is-active' : ''}`}>
        <div className="navbar-start">
          <Link className="navbar-item" to={"/"}>
            Overview
          </Link>
          <div className="navbar-item has-dropdown is-hoverable">
            <a className="navbar-link">
              Content Management
            </a>
            <div className="navbar-dropdown">
              <Link className="navbar-item" to={"/explore"}>
                Explore Section
              </Link>
              <Link className="navbar-item" to={"/tags"}>
                Tags
              </Link>
              <Link className="navbar-item" to={"/news"}>
                The News <span className="new">New</span>
              </Link>
              <Link className="navbar-item" to={"/releases"}>
                Releases <span className="new">New</span>
              </Link>
              <Link className="navbar-item" to={"/giveaways"}>
                Giveaways
              </Link>
              <Link className="navbar-item" to={"/retailers"}>
                Retailers <span className="new">New</span>
              </Link>
            </div>
          </div>
          <Link className="navbar-item" to={"/permissions"} aria-label={"Coming Soon"}>
            Permission Management
          </Link>
          <Link className="navbar-item" to={"/affiliates"} aria-label={"Coming Soon"}>
            Affiliate Links
          </Link>
          <Link className="navbar-item" to={"/permissions"} aria-label={"Coming Soon"}>
            Logs
          </Link>
          <a className="navbar-item" aria-label={"Coming Soon"}>
            Tickets
          </a>
        </div>
      </div>
    </nav>
  );
}

export default Navigation;