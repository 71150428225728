import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Navigation from '../components/Navigation';
import Explore from './Explore';
import Overview from './Overview';
import News from './News';
import Giveaways from "./Giveaways";
import Releases from './Releases';
import OauthRedirectHandler from './OauthRedirectHandler';
import Tags from './Tags';
import Retailers from './Retailers';

const Dashboard: React.FC<{}> = ({}) => {
  return (
    <Router>
      <Navigation />
      <Switch>
        <Route path={"/"} exact children={<Overview />}/>
        <Route path={"/explore"} children={<Explore />}/>
        <Route path={"/news"} children={<News />}/>
        <Route path={"/tags"} children={<Tags />}/>
        <Route path={"/releases"} children={<Releases />}/>
        <Route path={"/giveaways"} children={<Giveaways />}/>
        <Route path={"/retailers"} children={<Retailers />}/>
        <Route path={"/oauth-redirect-handler"} children={<OauthRedirectHandler />} />
      </Switch>
    </Router>
  );
}

export default Dashboard;