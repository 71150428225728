import React, { useContext, useEffect, useState } from 'react';
import VisibleImage from "../assets/images/visible.svg";
import InvisibleImage from "../assets/images/invisible.svg";
import LoadingImage from "../assets/images/loading.svg";
import { SneakerSeekerAdminAPI } from '../util/sssdk';
import { UserContext } from '../stores/UserContext';
import Tag from '../types/Tag';

type Props = {
  id: string;
  initialTags: Tag[];
  dataType: "article" | "explore";
  cb: () => {};
}

const TagManager: React.FC<Props> = ({ id, initialTags, dataType, cb }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [tagsLoading, setTagsLoading] = useState<boolean>(true);
  const [availableTags, setAvailableTags] = useState<Tag[]>([]);
  const [selectedTag, setSelectedTag] = useState<string>("");
  const { token } = useContext(UserContext);
  const api = new SneakerSeekerAdminAPI(token);

  useEffect(() => {
    (async () => {
      try {
        const tagsLoaded = await api.getAllTags();

        // TODO fix this
        const tagsAvail = tagsLoaded.filter((tag, index) => {
          let tagInUse = false;
          initialTags.forEach((t, i) => {
            tagInUse = tag.id === t.id;
          });

          if (!tagInUse) {
            return tag;
          }
        });

        setSelectedTag(tagsAvail[tagsAvail.length - 1].id);

        setAvailableTags(tagsAvail);

        setTagsLoading(false);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const tagPost = async () => {
    setLoading(true);
    try {
      await api.tag(selectedTag, id, dataType);
      setLoading(false);
      cb();
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  const removeTag = async (tagId: string) => {
    setLoading(true);
    try {
      const success = await api.untag(tagId, id, dataType);
      if (success) {
        cb();
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <h3 className={"title is-4"}>Tag Manager</h3>
      <table className="table is-fullwidth">
        <thead>
        <tr>
          <td colSpan={2}>
            <div className={`select input-span ${tagsLoading ? 'is-loading' : ''}`}>
              <select
                name="tag"
                style={{width: "100%"}}
                disabled={tagsLoading}
                onChange={(e) => setSelectedTag(e.target.value)}
                // TODO: Fix the following
                // defaultValue={selectedTag}
              >
                {tagsLoading
                  ? <option>Loading</option>
                  : availableTags.map(tag => <option key={tag.id} value={tag.id} selected={selectedTag === tag.id}>{tag.name}</option>)
                }
              </select>
            </div>
            <button
              className={`button ${loading ? 'is-loading' : ''}`}
              disabled={loading}
              onClick={tagPost}
            >Tag</button>
          </td>
        </tr>
        <tr>
          <th>Name</th>
          <th/>
        </tr>
        </thead>
        <tbody>
          {initialTags.map(tag => (
            <tr key={tag.id}>
              <td>{tag.name}</td>
              <td style={{ textAlign: "right" }}>
                <button
                  className="button is-danger is-small"
                  onClick={() => removeTag(tag.tagId)}
                  disabled={loading}
                >
                  Untag
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

export default TagManager;