import React, { useEffect } from 'react';
import {
  Switch,
  Route,
  Link,
  useRouteMatch
} from "react-router-dom";
import ReleaseTableView from '../components/releases/ReleaseTableView';
import CreateReleasePostForm from '../components/releases/CreateReleaseForm';
import EditReleasePostForm from '../components/releases/EditReleaseForm';

const Release: React.FC<{}> = ({}) => {
  let { path, isExact } = useRouteMatch();

  return (
    <div className={"page"}>
      <section className={"section"}>
        <div className="level">
          <div className="level-left">
            <h2 className="title is-2">Release Manager</h2>
          </div>
          <div className="level-right">
            {isExact
              ? <Link to={`${path}/create`}>Create</Link>
              : <Link to={"/releases"}>Go Back</Link>
            }
          </div>
        </div>
        <hr/>
        <Switch>
          <Route exact path={path}>
            <ReleaseTableView />
          </Route>
          <Route exact path={`${path}/create`}>
            <CreateReleasePostForm />
          </Route>
          <Route
            path={`${path}/:id`}
            children={(props) => <EditReleasePostForm id={props.match?.params?.id}/>}
          />
        </Switch>
      </section>
    </div>
  );
}

export default Release;