import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import * as yup from "yup";
import { api } from '../../App';

interface ICreateExploreInputs {
  title: string;
  description: string;
  link: string;
  image: FileList
}

const SUPPORTED_FORMATS = [
  "image/jpeg",
  "image/png"
];

const schema = yup.object().shape({
  title: yup.string().required(),
  description: yup.string().required(),
  link: yup.string().required(),
  image: yup.mixed().required().test(
    "fileFormat",
    "Unsupported Format (.jpeg and .png only)",
    value => value[0] && SUPPORTED_FORMATS.includes(value[0].type)
  )
})

function CreateExplorePostForm() {
  const [fileName, setFileName] = useState("Select an Image to Upload.")
  const [notificationShown, toggleNotification] = useState<boolean>(false);
  const { register, handleSubmit, errors, setValue } = useForm<ICreateExploreInputs>({
    resolver: yupResolver(schema)
  });

  const onSubmit = async ({ title, description, image, link }: ICreateExploreInputs) => {
    try {
      const actualImage = image[0];
      const explorePostIsCreated = await api?.createExplorePost(
        title, link, actualImage, description
      );
      if (explorePostIsCreated) {
        // Clear form and show pop up or something
        toggleNotification(true);
        setValue("title", "");
        setValue("description", "");
        setValue("link", "");
        setFileName("Select an Image to Upload.");
      } else {

      }
    } catch (error) {
      // Display an error message
    }
  }

  //@ts-ignore
  let imageErrors = errors.image?.message;

  return (
    <section>
      {notificationShown
        ? (
          <div className="notification">
            <button className="delete" onClick={() => toggleNotification(false)}/>
            Explore post created.
          </div>
        )
        : null
      }
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="field">
          <label className="label">Title</label>
          <div className="control">
            <input className="input" type="text" placeholder="Title" name="title" ref={register}/>
          </div>
          <p className="help is-danger">{errors.title?.message}</p>
        </div>

        <div className="field">
          <label className="label">Description</label>
          <div className="control">
            <textarea className="textarea" placeholder="Description" name="description" ref={register}/>
          </div>
          <p className="help is-danger">{errors.description?.message}</p>
        </div>
        <div className="field">
          <label className="label">Link</label>
          <div className="control">
            <input type="text" className="input" placeholder="Link" name="link" ref={register} />
          </div>
          <p className="help is-danger">{errors.link?.message}</p>
        </div>
        <div className="field">
          <label className="label">Header Image</label>
          <div className="columns">
            <div className="column">
              <div className="control">
                <div className="file is-boxed has-name">
                  <label className="file-label">
                    <input className="file-input" type="file" name="image" accept="image/png, image/jpeg"
                     ref={register}
                     onChange={e => {
                       const fileName = e.target.value.split('\\').pop();
                       setFileName(fileName!);
                     }}
                    />
                    <span className="file-cta">
                  <span className="file-label">
                    Image Upload
                  </span>
                </span>
                    <span className="file-name">
                    {fileName}
                  </span>
                  </label>
                </div>
                <p className="help is-danger">{imageErrors}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="field is-grouped">
          <div className="control">
            <input className="button is-link" type="submit" value={"Create Post"}/>
          </div>
        </div>
      </form>
    </section>
  );
}

export default CreateExplorePostForm;