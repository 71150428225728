import React, { useContext, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import * as yup from "yup";
import { SneakerSeekerAdminAPI } from "../../util/sssdk";
import { UserContext } from '../../stores/UserContext';
import Post from '../../types/Post';
import TagManager from '../TagManager';
import Tag from '../../types/Tag';
import Article from '../../types/Article';
import EditorJs from 'react-editor-js';
// @ts-ignore
import Header from "@editorjs/header";
import { API, OutputData } from '@editorjs/editorjs';

interface IEditExploreInputs {
  title: string;
  description: string;
  link: string;
  image: FileList
}

const SUPPORTED_FORMATS = [
  "image/jpeg",
  "image/png"
];

const schema = yup.object().shape({
  title: yup.string().required(),
  image: yup.mixed()
})

const EditArticleForm: React.FC<{ id: string }> = ({ id }) => {
  const { token } = useContext(UserContext);
  const api = new SneakerSeekerAdminAPI(token);
  const [fileName, setFileName] = useState("Select an Image to Upload.")
  const [notificationShown, toggleNotification] = useState<boolean>(false);
  const [post, setPost] = useState<Article>();
  const [tags, setTags] = useState<Tag[]>([]);
  const [content, setContent] = useState<any>({});
  const { register, handleSubmit, errors, setValue } = useForm<IEditExploreInputs>({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    (async () => {
      await fetchPost();
    })();
  }, [])

  const onSubmit = async ({ title, image }: IEditExploreInputs) => {
    try {
      const actualImage = image[0] || null;
      const explorePostIsCreated = await api.updateArticle(
        id, title, actualImage, JSON.stringify(content)
      );
      if (explorePostIsCreated) {
        toggleNotification(true);
        await fetchPost();
        setFileName("Select an Image to Upload.");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const fetchPost = async () => {
    const postResponse = await api.getArticle(id);
    const p = postResponse.article;
    setTags(postResponse.tags);
    setPost(postResponse.article);
    setValue("title", p.title);
    setContent(JSON.parse(p.content) || {});
    setValue("link", p.image || "");
  }

  //@ts-ignore
  let imageErrors = errors.image?.message;

  if (!post) return <progress className="progress is-small is-primary" max="100">15%</progress>;
  return (
    <section>
      {notificationShown
        ? (
          <div className="notification">
            <button className="delete" onClick={() => toggleNotification(false)}/>
            Article updated.
          </div>
        )
        : null
      }
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="field">
          <label className="label">Title</label>
          <div className="control">
            <input className="input" type="text" placeholder="Title" name="title" ref={register}/>
          </div>
          <p className="help is-danger">{errors.title?.message}</p>
        </div>

        <div className="field">
          <label className="label">Content</label>
          <div className="control">
            <div className={"editor"}>
              <EditorJs data={JSON.parse(post.content)} tools={{ header: Header }} onChange={(api: API, data?: OutputData) => setContent(data)}/>
            </div>
          </div>
        </div>
        <div className="field">
          <label className="label">Header Image</label>
          <div className="columns">
            <div className="column">
              <div className="control">
                <div className="file is-boxed has-name">
                  <label className="file-label">
                    <input className="file-input" type="file" name="image" accept="image/png, image/jpeg"
                       ref={register}
                       onChange={e => {
                         const fileName = e.target.value.split('\\').pop();
                         setFileName(fileName!)
                       }}
                    />
                    <span className="file-cta">
                  <span className="file-label">
                    Image Upload
                  </span>
                </span>
                    <span className="file-name">
                    {fileName}
                  </span>
                  </label>
                </div>
                <p className="help is-danger">{imageErrors}</p>
              </div>
            </div>
            <div className="column">
              <img src={post.image} alt="" className="thumbnail"/>
            </div>
          </div>
        </div>
        <div className="field is-grouped">
          <div className="control">
            <input className="button is-link" type="submit" value={"Update Article"}/>
          </div>
        </div>
      </form>
      <hr/>
      <TagManager
        initialTags={tags}
        dataType={"article"}
        id={id}
        cb={fetchPost}
      />
    </section>
  );
}

export default EditArticleForm;