import a from "axios";
import Post from "../types/Post";
import ResponseBody from "../types/ResponseBody";
import PostMin from "../types/PostMin";
import ArticleMin from "../types/ArticleMin";
import Article from "../types/Article";
import Release from "../types/Release";
import Sneaker from "../types/Sneaker";
import SneakerMin from "../types/SneakerMin";
import ReleaseMin from "../types/ReleaseMin";
import baseURL from "../util/base-url"
import Tag from '../types/Tag';
import Retailer from '../types/Retailer';
import formatDate from "../util/format-date";
import Link from "../types/Link";
import { DateTime } from "luxon";

export type LinkType = "FCFS" | "RESALE" | "RAFFLE";

const axios = a.create({
  validateStatus: function (status) {
    return status >= 200 && status < 500; // default
  },
});

// Public API
class SneakerSeekerAPI {
  public token: string;
  constructor(token: string) {
    if (!token) throw new Error("Missing Token");
    this.token = token;
  }

  public async getExplorePosts(page: number = 0, query: string | null): Promise<{ posts: PostMin[]; page: number }> {
    try {
      let response = await axios.get(`${baseURL}/explore?page=${page}${query ? "&search=" + encodeURIComponent(query) : ''}`, {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      });

      if ((response.data as ResponseBody).token) {
        console.log("updating token");
        localStorage.setItem("user_access_token", ((response.data as ResponseBody).token as string));
        this.token = ((response.data as ResponseBody).token as string);
      }

      const responseBodyData: any = (response.data as ResponseBody).data;

      return {
        posts: responseBodyData.rows,
        page: responseBodyData.count
      };
    } catch (error) {
      throw new Error(error);
    }
  }
  public async getExplorePost(id: string): Promise<{ post: Post; tags: Tag[] }> {
    if (!id) {
      throw new Error("Missing ID from method parameters.");
    }
    try {
      const response: any = await axios.get(`${baseURL}/explore/${id}`, {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      });

      if ((response.data as ResponseBody).token) {
        console.log("updating token");
        localStorage.setItem("user_access_token", ((response.data as ResponseBody).token as string));
        this.token = ((response.data as ResponseBody).token as string);
      }

      return {
        post: ((response.data as ResponseBody).data as any).post as Post,
        tags: ((response.data as ResponseBody).data as any).tags as Tag[]
      }
    } catch (error) {
      throw new Error(error);
    }
  }
  public async getArticles(page: number = 0, query: string | null): Promise<{ articles: ArticleMin[]; page: number }> {
    try {
      const response = await axios.get(`${baseURL}/article?page=${page}${query ? "&search=" + encodeURIComponent(query) : ''}`, {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      });

      if ((response.data as ResponseBody).token) {
        console.log("updating token");
        localStorage.setItem("user_access_token", ((response.data as ResponseBody).token as string));
        this.token = ((response.data as ResponseBody).token as string);
      }

      const responseBodyData: any = (response.data as ResponseBody).data;
      return {
        articles: responseBodyData.rows,
        page: responseBodyData.count
      };
    } catch (error) {
      throw new Error(error);
    }
  }
  public async getArticle(id: string): Promise<{ article: Article; tags: Tag[] }> {
    if (!id) {
      throw new Error("Missing ID from method parameters.");
    }
    try {
      const response = await axios.get(`${baseURL}/article/${id}`, {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      });

      if ((response.data as ResponseBody).token) {
        console.log("updating token");
        localStorage.setItem("user_access_token", ((response.data as ResponseBody).token as string));
        this.token = ((response.data as ResponseBody).token as string);
      }

      return {
        article: ((response.data as ResponseBody).data as any).article as Article,
        tags: ((response.data as ResponseBody).data as any).tags as Tag[]
      }
    } catch (error) {
      throw new Error(error);
    }
  }
  public async getReleases(page: number = 0, query: string | null): Promise<{ releases: ReleaseMin[], page: number }> {
    try {
      const response = await axios.get(`${baseURL}/release?page=${page}${query ? "&search=" + encodeURIComponent(query) : ''}`, {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      });

      if ((response.data as ResponseBody).token) {
        console.log("updating token");
        localStorage.setItem("user_access_token", ((response.data as ResponseBody).token as string));
        this.token = ((response.data as ResponseBody).token as string);
      }

      const responseBodyData: any = (response.data as ResponseBody).data;
      return {
        releases: responseBodyData,
        page: 0
      };
    } catch (error) {
      throw new Error(error);
    }
  }
  public async getRelease(id: string): Promise<Release> {
    if (!id) {
      throw new Error("Missing ID from method parameters.");
    }
    try {
      const response = await axios.get(`${baseURL}/release/${id}`, {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      });

      if ((response.data as ResponseBody).token) {
        console.log("updating token");
        localStorage.setItem("user_access_token", ((response.data as ResponseBody).token as string));
        this.token = ((response.data as ResponseBody).token as string);
      }

      return ((response.data as ResponseBody).data as Release);
    } catch (error) {
      throw new Error(error);
    }
  }
  public async getMarketplace(query: string | null): Promise<SneakerMin[]> {
    try {
      const response = await axios.get(`${baseURL}/marketplace${query ? "?search=" + encodeURIComponent(query) : ''}`, {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      });

      if ((response.data as ResponseBody).token) {
        console.log("updating token");
        localStorage.setItem("user_access_token", ((response.data as ResponseBody).token as string));
        this.token = ((response.data as ResponseBody).token as string);
      }

      return ((response.data as ResponseBody).data as SneakerMin[]);
    } catch (error) {
      throw new Error(error);
    }
  }
  public async getSneaker(id: string): Promise<Sneaker> {
    if (!id) {
      throw new Error("Missing ID from method parameters.");
    }
    try {
      const response = await axios.get(`${baseURL}/marketplace/${id}`, {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      });

      if ((response.data as ResponseBody).token) {
        console.log("updating token");
        localStorage.setItem("user_access_token", ((response.data as ResponseBody).token as string));
        this.token = ((response.data as ResponseBody).token as string);
      }

      return ((response.data as ResponseBody).data as Sneaker);
    } catch (error) {
      throw new Error(error);
    }
  }
  public async getAllRetailers(): Promise<Retailer[]> {
    try {
      const response = await axios.get(`${baseURL}/retailer`, {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      });

      if ((response.data as ResponseBody).token) {
        console.log("updating token");
        localStorage.setItem("user_access_token", ((response.data as ResponseBody).token as string));
        this.token = ((response.data as ResponseBody).token as string);
      }

      return (response.data as ResponseBody).data as Retailer[];
    } catch (error) {
      throw new Error(error);
    }
  }
}

export default SneakerSeekerAPI;


// Private API
export class SneakerSeekerAdminAPI extends SneakerSeekerAPI {
  public async createExplorePost(title: string, link: string, image: File, description: string = ""): Promise<boolean> {
    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("link", link);
      formData.append("description", description);
      formData.append("image", image);

      const response = await axios.post(`${baseURL}/explore`,
        formData,
        {
        headers: {
          "Authorization": `Bearer ${this.token}`,
          "Content-Type": "multipart/form-data"
        }
      });

      if ((response.data as ResponseBody).token) {
        console.log("updating token");
        localStorage.setItem("user_access_token", ((response.data as ResponseBody).token as string));
        this.token = ((response.data as ResponseBody).token as string);
      }


      return response.status >= 200 && response.status < 300;
    } catch (error) {
      throw new Error(error);
    }
  }
  public async updateExplorePost(id: string, title: string, link: string, image: File | null, description: string = ""): Promise<boolean> {
    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("link", link);
      formData.append("description", description);
      if (image) formData.append("image", image);

      const response = await axios.put(`${baseURL}/explore/${id}`,
        formData,
        {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      });

      if ((response.data as ResponseBody).token) {
        console.log("updating token");
        localStorage.setItem("user_access_token", ((response.data as ResponseBody).token as string));
        this.token = ((response.data as ResponseBody).token as string);
      }


      return response.status >= 200 && response.status < 300;
    } catch (error) {
      throw new Error(error);
    }
  }
  public async destroyExplorePost(id: string): Promise<boolean> {
    try {
      const response = await axios.delete(`${baseURL}/explore/${id}`,{
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      });

      if ((response.data as ResponseBody).token) {
        console.log("updating token");
        localStorage.setItem("user_access_token", ((response.data as ResponseBody).token as string));
        this.token = ((response.data as ResponseBody).token as string);
      }


      return response.status >= 200 && response.status < 300;
    } catch (error) {
      throw new Error(error);
    }
  }
  public async toggleExplorePostVisibility(id: string): Promise<boolean> {
    try {
      const response = await axios.put(`${baseURL}/explore/${id}/toggle-visibility`,{},{
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      });

      if ((response.data as ResponseBody).token) {
        console.log("updating token");
        localStorage.setItem("user_access_token", ((response.data as ResponseBody).token as string));
        this.token = ((response.data as ResponseBody).token as string);
      }


      return response.status >= 200 && response.status < 300;
    } catch (error) {
      throw new Error(error);
    }
  }
  public async toggleArticleVisibility(id: string): Promise<boolean> {
    try {
      const response = await axios.put(`${baseURL}/article/${id}/toggle-visibility`,{},{
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      });

      if ((response.data as ResponseBody).token) {
        console.log("updating token");
        localStorage.setItem("user_access_token", ((response.data as ResponseBody).token as string));
        this.token = ((response.data as ResponseBody).token as string);
      }

      return response.status >= 200 && response.status < 300;
    } catch (error) {
      throw new Error(error);
    }
  }
  public async toggleReleaseVisibility(id: string): Promise<boolean> {
    try {
      const response = await axios.put(`${baseURL}/release/${id}/toggle-visibility`,{},{
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      });

      if ((response.data as ResponseBody).token) {
        console.log("updating token");
        localStorage.setItem("user_access_token", ((response.data as ResponseBody).token as string));
        this.token = ((response.data as ResponseBody).token as string);
      }


      return response.status >= 200 && response.status < 300;
    } catch (error) {
      throw new Error(error);
    }
  }
  public async getAllTags(): Promise<Tag[]> {
    try {
      const response = await axios.get(`${baseURL}/tag`,{
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      });

      if ((response.data as ResponseBody).token) {
        console.log("updating token");
        localStorage.setItem("user_access_token", ((response.data as ResponseBody).token as string));
        this.token = ((response.data as ResponseBody).token as string);
      }

      return response.data.data;
    } catch (error) {
      throw new Error(error);
    }
  }
  public async createTag(name: string): Promise<boolean> {
    try {
      const response = await axios.post(`${baseURL}/tag`,{
        name
      },{
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      });

      if ((response.data as ResponseBody).token) {
        console.log("updating token");
        localStorage.setItem("user_access_token", ((response.data as ResponseBody).token as string));
        this.token = ((response.data as ResponseBody).token as string);
      }


      return response.status >= 200 && response.status < 300;
    } catch (error) {
      throw new Error(error);
    }
  }
  public async deleteTag(id: string): Promise<boolean> {
    try {
      const response = await axios.delete(`${baseURL}/tag/${id}`,{
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      });

      if ((response.data as ResponseBody).token) {
        console.log("updating token");
        localStorage.setItem("user_access_token", ((response.data as ResponseBody).token as string));
        this.token = ((response.data as ResponseBody).token as string);
      }

      return response.status >= 200 && response.status < 300;
    } catch (error) {
      throw new Error(error);
    }
  }
  public async tag(tagId: string, itemId: string, itemType: "explore" | "article"): Promise<boolean> {
    try {
      const response = await axios.post(`${baseURL}/tag/${tagId}/to-${itemType === "article" ? "article" : "explore-post"}/${itemId}`, {}, {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      });

      if ((response.data as ResponseBody).token) {
        console.log("updating token");
        localStorage.setItem("user_access_token", ((response.data as ResponseBody).token as string));
        this.token = ((response.data as ResponseBody).token as string);
      }

      return response.status >= 200 && response.status < 300;
    } catch (error) {
      throw new Error(error);
    }
  }
  public async untag(tagId: string, itemId: string, itemType: "explore" | "article"): Promise<boolean> {
    try {
      const response = await axios.delete(`${baseURL}/tag/${tagId}/to-${itemType === "article" ? "article" : "explore-post"}/${itemId}`,{
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      });

      if ((response.data as ResponseBody).token) {
        console.log("updating token");
        localStorage.setItem("user_access_token", ((response.data as ResponseBody).token as string));
        this.token = ((response.data as ResponseBody).token as string);
      }


      return response.status >= 200 && response.status < 300;
    } catch (error) {
      throw new Error(error);
    }
  }
  public async createArticle(title: string, image: File, content: string): Promise<boolean> {
    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("content", content);
      formData.append("image", image);

      const response = await axios.post(`${baseURL}/article`,
        formData,
        {
        headers: {
          "Authorization": `Bearer ${this.token}`,
          "Content-Type": "multipart/form-data"
        }
      });

      if ((response.data as ResponseBody).token) {
        console.log("updating token");
        localStorage.setItem("user_access_token", ((response.data as ResponseBody).token as string));
        this.token = ((response.data as ResponseBody).token as string);
      }

      return response.status >= 200 && response.status < 300;
    } catch (error) {
      throw new Error(error);
    }
  }
  public async destroyArticle(id: string): Promise<boolean> {
    try {
      const response = await axios.delete(`${baseURL}/article/${id}`,{
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      });

      if ((response.data as ResponseBody).token) {
        console.log("updating token");
        localStorage.setItem("user_access_token", ((response.data as ResponseBody).token as string));
        this.token = ((response.data as ResponseBody).token as string);
      }

      return response.status >= 200 && response.status < 300;
    } catch (error) {
      throw new Error(error);
    }
  }
  public async updateArticle(id: string, title: string, image: File | null, content: string): Promise<boolean> {
    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("content", content);
      if (image) formData.append("image", image);

      const response = await axios.put(`${baseURL}/article/${id}`,
        formData,
      {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      });

      if ((response.data as ResponseBody).token) {
        console.log("updating token");
        localStorage.setItem("user_access_token", ((response.data as ResponseBody).token as string));
        this.token = ((response.data as ResponseBody).token as string);
      }

      return response.status >= 200 && response.status < 300;
    } catch (error) {
      throw new Error(error);
    }
  }
  public async createRetailer(name: string, image: File): Promise<boolean> {
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("image", image);

      const response = await axios.post(`${baseURL}/retailer`,
        formData,
        {
          headers: {
            "Authorization": `Bearer ${this.token}`,
            "Content-Type": "multipart/form-data"
          }
        });

      if ((response.data as ResponseBody).token) {
        console.log("updating token");
        localStorage.setItem("user_access_token", ((response.data as ResponseBody).token as string));
        this.token = ((response.data as ResponseBody).token as string);
      }


      return response.status >= 200 && response.status < 300;
    } catch (error) {
      throw new Error(error);
    }
  }
  public async destroyRetailer(id: string): Promise<boolean> {
    try {
      const response = await axios.delete(`${baseURL}/retailer/${id}`,{
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      });

      if ((response.data as ResponseBody).token) {
        console.log("updating token");
        localStorage.setItem("user_access_token", ((response.data as ResponseBody).token as string));
        this.token = ((response.data as ResponseBody).token as string);
      }

      return response.status >= 200 && response.status < 300;
    } catch (error) {
      throw new Error(error);
    }
  }
  public async destroyRelease(id: string): Promise<boolean> {
    try {
      const response = await axios.delete(`${baseURL}/release/${id}`,{
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      });

      if ((response.data as ResponseBody).token) {
        console.log("updating token");
        localStorage.setItem("user_access_token", ((response.data as ResponseBody).token as string));
        this.token = ((response.data as ResponseBody).token as string);
      }


      return response.status >= 200 && response.status < 300;
    } catch (error) {
      throw new Error(error);
    }
  }
  public async createRelease(name: string, image: File, description: string, sku: string, price: number, colorWay: string, releaseDate: Date): Promise<boolean> {
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("description", description);
      formData.append("image", image);
      formData.append("sku", sku);
      formData.append("price", price.toString());
      formData.append("colorWay", colorWay);
      formData.append("releaseDate", formatDate(releaseDate));

      const response = await axios.post(`${baseURL}/release`,
        formData,
        {
        headers: {
          "Authorization": `Bearer ${this.token}`,
          "Content-Type": "multipart/form-data"
        }
      });

      if ((response.data as ResponseBody).token) {
        console.log("updating token");
        localStorage.setItem("user_access_token", ((response.data as ResponseBody).token as string));
        this.token = ((response.data as ResponseBody).token as string);
      }

      return response.status >= 200 && response.status < 300;
    } catch (error) {
      throw new Error(error);
    }
  }
  public async updateRelease(id: string, name: string, image: File | null, description: string, sku: string, price: number, colorWay: string, releaseDate: Date): Promise<boolean> {
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("description", description);
      formData.append("sku", sku);
      formData.append("price", price.toString());
      formData.append("colorWay", colorWay);
      formData.append("releaseDate", formatDate(releaseDate));
      if (image) formData.append("image", image);

      const response = await axios.put(`${baseURL}/release/${id}`,
        formData,
      {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      });

      if ((response.data as ResponseBody).token) {
        console.log("updating token");
        localStorage.setItem("user_access_token", ((response.data as ResponseBody).token as string));
        this.token = ((response.data as ResponseBody).token as string);
      }

      return response.status >= 200 && response.status < 300;
    } catch (error) {
      throw new Error(error);
    }
  }
  public async getReleaseLinks(id: string): Promise<Link[]> {
    try {
      const release = await this.getRelease(id);

      return release.links;
    } catch (error) {
      throw new Error(error);
    }
  }
  public async createReleaseLink(releaseId: string, retailerId: string, type: LinkType, link: string, dropDate: Date): Promise<boolean> {
    try {
      const newDropDate = DateTime.fromISO(dropDate.toISOString(), {zone: 'utc'})
      const response = await axios.post(`${baseURL}/retailer/${releaseId}/link/${retailerId}`,
          {
            type,
            link,
            dropDate: newDropDate.toString(),
          },
          {
          headers: {
            "Authorization": `Bearer ${this.token}`
          }
        }
      );

      return response.status >= 200 && response.status < 300;
    } catch (error) {
      throw new Error(error);
    }
  }
  public async destroyReleaseRetailLink(id: string): Promise<boolean> {
    try {
      const response = await axios.delete(`${baseURL}/retailer/${id}/link/remove`,{
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      });

      return response.status >= 200 && response.status < 300;
    } catch (error) {
      throw new Error(error);
    }
  }
  public async updateReleaseLink(id: string, link: string): Promise<boolean> {
    try {
      const response = await axios.put(`${baseURL}/retailer/link/${id}`, {
        link
      }, {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      });

      return response.status >= 200 && response.status < 300;
    } catch (error) {
      throw new Error(error);
    }
  }
  public async updateReleaseDate(id: string, dropDate: Date): Promise<boolean> {
    try {
      const response = await axios.put(`${baseURL}/retailer/link/${id}`, {
        dropDate
      }, {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      });

      return response.status >= 200 && response.status < 300;
    } catch (error) {
      throw new Error(error);
    }
  }
}
