import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../stores/UserContext';
import { SneakerSeekerAdminAPI } from '../util/sssdk';
import DeleteButton from '../components/DeleteButton';
import Tag from '../types/Tag';

const Tags: React.FC<{}> = ({}) => {
  const { token } = useContext(UserContext);
  const api = new SneakerSeekerAdminAPI(token);
  const [tags, setTags] = useState<Tag[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [tagValue, setTagValue] = useState<string>("");

  useEffect(() => {
    (async () => {
      await fetchTags();
    })();
  }, []);

  const fetchTags = async () => {
    try {
      setTags(await api.getAllTags());
    } catch (error) {
      console.log(error);
    }
  }

  const createTag = async () => {
    setLoading(true);
    try {
      const success = await api.createTag(tagValue);

      if (success) {
        setTagValue("");
        await fetchTags();
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div className={"page"}>
      <section className={"section"}>
        <div className="level">
          <div className="level-left">
            <h2 className="title is-2">Tags Manager</h2>
          </div>
          <div className="level-right" />
        </div>
        <hr/>
        <table className="table is-fullwidth">
          <thead>
            <tr>
              <td colSpan={2}>
                <div className="control" style={{ width: "100%", maxWidth: "calc(100% - 80px)", display: "inline-block"}}>
                  <input
                    className="input"
                    type="text"
                    placeholder="Tag Name"
                    name="name"
                    value={tagValue}
                    onChange={e => setTagValue(e.target.value)}
                  />
                </div>
                <button
                  className={`button ${loading ? 'is-loading' : ''}`}
                  disabled={loading}
                  onClick={createTag}
                >Create</button>
              </td>
            </tr>
            <tr>
              <th>Name</th>
              <th/>
            </tr>
          </thead>
          <tbody>
          {tags.map(tag => (
            <tr>
              <td>{tag.name}</td>
              <td style={{ textAlign: "right" }}>
                <DeleteButton id={tag.id} dataType={"tag"} cb={fetchTags} />
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </section>
    </div>
  );
}

export default Tags;