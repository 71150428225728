import React from 'react';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import ArticleTableView from "../components/news/ArticleTableView";
import CreateArticleForm from '../components/news/CreateArticleForm';
import EditArticleForm from '../components/news/EditArticleForm';

const News: React.FC<{}> = ({}) => {
  let { path, isExact } = useRouteMatch();
  return (
    <div className={"page"}>
      <section className={"section"}>
        <div className="level">
          <div className="level-left">
            <h2 className="title is-2">News Manager</h2>
          </div>
          <div className="level-right">
            {isExact
              ? <Link to={`${path}/create`}>Create</Link>
              : <Link to={"/news"}>Go Back</Link>
            }
          </div>
        </div>
        <hr/>
        <Switch>
          <Route exact path={path}>
            <ArticleTableView />
          </Route>
          <Route exact path={`${path}/create`}>
            <CreateArticleForm />
          </Route>
          <Route
            path={`${path}/:id`}
            children={(props) => <EditArticleForm id={props.match?.params?.id}/>}
          />
        </Switch>
      </section>
    </div>
  );
}

export default News;