import React, { useContext, useState } from 'react';
import VisibleImage from "../assets/images/visible.svg";
import InvisibleImage from "../assets/images/invisible.svg";
import LoadingImage from "../assets/images/loading.svg";
import { SneakerSeekerAdminAPI } from '../util/sssdk';
import { UserContext } from '../stores/UserContext';
import Tag from '../types/Tag';
import { Link } from 'react-router-dom';

type Props = {
  id: string;
  dataType: "explore" | "article" | "release" | "tag" | "retailer" | "release-retailer-link";
  cb: () => {};
}

const DeleteButton: React.FC<Props> = ({ id, dataType, cb }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [areYouSure, setAreYouSure] = useState<boolean>(false)
  const { token } = useContext(UserContext);

  const handleDelete = async () => {
    if (!areYouSure) {
      setAreYouSure(true);
      return setTimeout(() => {
        setAreYouSure(false);
      }, 5000);
    }

    setLoading(true);
    setAreYouSure(false);

    const api = new SneakerSeekerAdminAPI(token);
    // // TODO: add switch case for datatype
    try {
      let success: boolean = false;

      switch (dataType) {
        case 'article':
          success = await api.destroyArticle(id);
          break;
        case 'explore':
          success = await api.destroyExplorePost(id);
          break;
        case 'retailer':
          success = await api.destroyRetailer(id);
          break;
        case 'release':
          success = await api.destroyRelease(id);
          break;
        case 'release-retailer-link':
          success = await api.destroyReleaseRetailLink(id);
          break;
        case 'tag':
          success = await api.deleteTag(id);
          break;
        default:
          break;
      }


      if (success) {
        await cb();
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <button
      onClick={handleDelete}
      className={`button is-small is-danger`}
      disabled={loading}
    >
      {loading
        ? <img src={LoadingImage} alt={"Loading.."}/>
        : areYouSure ? "Are you sure?" : "Delete"
      }
    </button>
  );
}

export default DeleteButton;
