// @ts-ignore
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import {LinkType, SneakerSeekerAdminAPI} from '../../util/sssdk';
import { UserContext } from '../../stores/UserContext';
import Tag from '../../types/Tag';
import Link from "../../types/Link";
import Retailer from "../../types/Retailer";
import DatePicker from "react-datepicker";
import DeleteButton from "../DeleteButton";
import { DateTime } from "luxon";

type Props = {
    id: string;
}

const EditLink: React.FC<{ link: Link; cb: () => void }> = ({ link, cb }) => {
    const { token } = useContext(UserContext);
    const api = new SneakerSeekerAdminAPI(token);
    const [value, setValue] = useState(link.link);
    const [modalIsVis, setModalIsVis] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const updateLink = async () => {
        setIsLoading(true);

        const success = await api.updateReleaseLink(link.id, value);

        if (success) {
            await cb();
            setIsLoading(false)
            setModalIsVis(false);
        }
    };

    return (
      <td>
          <div className="popup-parent">
              <div className={`popup ${modalIsVis ? "vis" : ""}`}>
                  <input
                    className={"input"}
                    type="text"
                    value={value}
                    onChange={e => setValue(e.target.value)}
                    placeholder={"Link (Leave empty for disabled)"}
                  />
                  <button
                    className={`button ${isLoading ? "is-loading" : ""}`}
                    onClick={updateLink}
                    disabled={isLoading}
                  >
                      Save
                  </button>
              </div>
              <button
                className="button is-small"
                onClick={() => setModalIsVis(!modalIsVis)}
              >
                  {modalIsVis ? "Hide" : "Edit"}
              </button>
          </div>
      </td>
    )
};

const EditDate: React.FC<{ link: Link; cb: () => void }> = ({ link, cb }) => {
    const { token } = useContext(UserContext);
    const api = new SneakerSeekerAdminAPI(token);
    const [value, setValue] = useState(new Date(link.dropDate));
    const [modalIsVis, setModalIsVis] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const updateLink = async () => {
        setIsLoading(true);

        const success = await api.updateReleaseDate(link.id, value);

        if (success) {
            await cb();
            setIsLoading(false)
            setModalIsVis(false);
        }
    };

    return (
      <td>
          <div className="popup-parent">
              <div className={`popup ${modalIsVis ? "vis" : ""}`}>
                  <DatePicker
                    selected={value}
                    onChange={date => setValue(date as Date)}
                    showTimeSelect
                    className={"input"}
                  />
                  <button
                    className={`button ${isLoading ? "is-loading" : ""}`}
                    onClick={updateLink}
                    disabled={isLoading}
                  >
                      Save
                  </button>
              </div>
              <button
                className="button is-small"
                onClick={() => setModalIsVis(!modalIsVis)}
              >
                  {modalIsVis ? "Hide" : "Edit"}
              </button>
          </div>
      </td>
    )
};

const ReleaseRetailerLink: React.FC<Props> = ({ id }) => {
    const { register, handleSubmit, errors } = useForm();
    const [loading, setLoading] = useState<boolean>(true);
    const [tagsLoading, setTagsLoading] = useState<boolean>(true);
    const [availableRetailers, setAvailableRetailers] = useState<Retailer[]>([]);
    const [availableLinks, setAvailableLinks] = useState<Link[]>([]);
    const [selectedTag, setSelectedTag] = useState<string>("");
    const [selectedType, setSelectedType] = useState<LinkType>("FCFS");
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [link, setLink] = useState("");
    const { token } = useContext(UserContext);
    const api = new SneakerSeekerAdminAPI(token);

    useEffect(() => {
        (async () => {
            setTagsLoading(true);
            try {
                // Get retailers
                const retailers = await api.getAllRetailers();
                setAvailableRetailers(retailers);
                setSelectedTag(retailers.length > 0 ? retailers[0].id : "");
                setTagsLoading(false);

                // Get current links
                await fetchLinks();

            } catch (error) {
                console.log(error);
            }
        })();
    }, []);

    const fetchLinks = async () => {
        try {
            setLoading(true);
            const links = await api.getReleaseLinks(id);
            setAvailableLinks(links)
            setLoading(false);
        } catch (error) {
            console.log(error)
        }
    }

    const addLink = async () => {
        setLoading(true);

        try {
            await api.createReleaseLink(id, selectedTag, selectedType, link, selectedDate);

            await fetchLinks();

        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false);
        }
    }

    const removeTag = async (tagId: string) => {
        setLoading(true);
    }

    return (
        <>
            <h3 className={"title is-4"}>Link Manager</h3>
            <table className="table is-fullwidth">
                <thead>
                <tr>
                    <td colSpan={5}>
                        <div className={`select w-100 ${tagsLoading ? 'is-loading' : ''}`} style={{ width: "100%"}}>
                            <select
                                name="tag"
                                className={" w-100"}
                                disabled={tagsLoading}
                                onChange={(e) => setSelectedTag(e.target.value)}
                                // TODO: Fix the following
                                // defaultValue={selectedTag}
                            >
                                {tagsLoading
                                    ? <option>Loading</option>
                                    : availableRetailers.map(retailer => <option key={retailer.id} value={retailer.id} selected={selectedTag === retailer.id}>{retailer.name}</option>)
                                }
                            </select>
                        </div>
                        <div className="select w-100">
                            <select name="linkType" onChange={(e) => setSelectedType(e.target.value as LinkType)} style={{width: "100%"}}>
                                <option value="FCFS" selected={selectedType === "FCFS"}>FCFS</option>
                                <option value="RESALE" selected={selectedType === "RESALE"}>RESALE</option>
                                <option value="RAFFLE" selected={selectedType === "RAFFLE"}>RAFFLE</option>
                            </select>
                        </div>
                        <input type="text" name="link" className={"input"} placeholder={"Link"} value={link} onChange={e => setLink(e.target.value)}  ref={register({ required: true })}/>
                        {errors.link && <p className="help is-danger">A link url is required.</p>}
                        {(selectedType !== "RESALE") ?
                            <DatePicker
                                selected={selectedDate}
                                className={"input w-100"}
                                onChange={date => setSelectedDate(date as Date)}
                                showTimeSelect
                            />
                            :
                            ""
                        }
                        <button
                            className={`button ${loading ? 'is-loading' : ''}`}
                            disabled={loading}
                            onClick={handleSubmit(addLink)}
                        >Add Link</button>
                    </td>
                </tr>
                <tr>
                    <th>Link</th>
                    <th/>
                    <th>Retailer</th>
                    <th>Release Date</th>
                    <th/>
                    <th/>
                </tr>
                </thead>
                <tbody>
                    {availableLinks.map(link => <tr key={link.id}>
                        <td>{link.link}</td>
                        <EditLink link={link} cb={fetchLinks} />
                        <td>{availableRetailers.map(r => r.id === link.retailerId && r.name)}</td>
                        <td>{DateTime.fromJSDate(new Date(link.dropDate)).toFormat('FFF')}</td>
                        <EditDate link={link} cb={fetchLinks} />
                        <td><DeleteButton id={link.id} dataType={"release-retailer-link"} cb={fetchLinks} /></td>
                    </tr>)}
                </tbody>
            </table>
        </>
    );
}

export default ReleaseRetailerLink;
