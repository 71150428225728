import React, { useContext, useState } from 'react';
import VisibleImage from "../assets/images/visible.svg";
import InvisibleImage from "../assets/images/invisible.svg";
import LoadingImage from "../assets/images/loading.svg";
import { SneakerSeekerAdminAPI } from '../util/sssdk';
import { UserContext } from '../stores/UserContext';

type Props = {
  id: string;
  dataType: "article" | "explore" | "release";
  initialValue: boolean;
}

const VisibilityToggle: React.FC<Props> = ({ id, initialValue, dataType }) => {
  const [visible, setVisibility] = useState<boolean>(initialValue);
  const [loading, setLoading] = useState<boolean>(false);
  const { token } = useContext(UserContext);

  const toggle = async () => {
    setLoading(true);
    const api = new SneakerSeekerAdminAPI(token);
    let success: boolean | null = null;
    switch (dataType) {
      case "article":
        try {
          success = await api.toggleArticleVisibility(id)
        } catch (error) {
          console.log(error);
        }
        break;
      case "explore":
        try {
          success = await api.toggleExplorePostVisibility(id)
        } catch (error) {
          console.log(error);
        }
        break;
      case "release":
        try {
          success = await api.toggleReleaseVisibility(id)
        } catch (error) {
          console.log(error);
        }
        break;
      default: throw Error("DataType nonexistent.");
    }

    if (success) {
      setVisibility(!visible);
    }
    setLoading(false);
  }

  return (
    <button
      onClick={toggle}
      className={"vis-btn"}
      disabled={loading}
      style={{
        cursor: loading ? "initial" : "pointer",
        backgroundImage: `url(${
          loading 
            ? LoadingImage
            : visible 
              ? VisibleImage 
              : InvisibleImage
        })`
      }}
    />
  );
}

export default VisibilityToggle;