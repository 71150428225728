import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { UserStore } from "./stores/UserContext";

ReactDOM.render(
  <React.StrictMode>
    <UserStore>
      <App />
    </UserStore>
  </React.StrictMode>,
  document.getElementById('root')
);
