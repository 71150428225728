import React, {createContext, useState} from 'react';

export const UserContext = createContext<any>({});

type Props = {
  children: React.ReactNode
}

export const UserStore: React.FC<Props> = ({ children }) => {
  const [state, setState] = useState<string>('');
  return (
    <UserContext.Provider value={{ token: state, setToken: setState}}>
      {children}
    </UserContext.Provider>
  );
};

