import React, { useEffect } from 'react';

function OauthRedirectHandler() {
  useEffect(() => {
    // get the URL parameters which will include the auth token
    const params = window.location.search;
    if (window.opener) {
      // send them to the opening window
      window.opener.postMessage(params);
      console.log(window.location);
      // close the popup
      window.close();
    }
  }, []);
  return (

    <p>Please wait...</p>
  );
}

export default OauthRedirectHandler;
//
