import React from 'react';

const Giveaways: React.FC<{}> = ({}) => {
  return (
    <div className={"page"}>
      <h2>Giveaway Manager</h2>
    </div>
  );
}

export default Giveaways;