import React, { useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import SneakerSeekerAPI from '../../util/sssdk';
import VisibilityToggle from '../VisibilityToggle';
import DeleteButton from '../DeleteButton';
import ArticleMin from '../../types/ArticleMin';

const ArticleTableView: React.FC<{}> = () => {
  const [explorePosts, setExplorePosts] = useState<ArticleMin[]>();
  const [total, setTotal] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(0);
  let { path, url } = useRouteMatch();

  useEffect(() => {
    (async () => {
      try {
        await fetchPosts();
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const fetchPosts = async (page: number = 0) => {
    const api = new SneakerSeekerAPI(localStorage.getItem("user_access_token")!);

    const posts = await api.getArticles(page, null);
    setExplorePosts(posts.articles);
    setTotal(posts.page);
  }

  const changePage = async (page: number) => {
    await fetchPosts(page);
    setCurrentPage(page);
  }

  const generatePages = () => {
    const pages = Math.ceil(total / 10);
    let blankArr: number[] = [];
    for (let i = 0; i < pages; i++) {
      blankArr[i] = i;
    }
    return blankArr.map((v, i) => (
      <li key={i}>
        <button
          className={`button is-small pagination-link ${currentPage === i ? 'is-current' : ''}`}
          aria-label={`Page ${i + 1}`}
          aria-current="page"
          onClick={() => changePage(i)}
        >
          {i + 1}
        </button>
      </li>
    ));
  }

  if (!explorePosts) {
    return (
      <progress className="progress is-small is-primary" max="100">15%</progress>
    )
  }

  return (
    <>
      <table className="table is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>Title</th>
            <th>Created</th>
            <th>Visibility</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {explorePosts.map((explorePost, i) => {
            const createdDate = new Date(explorePost.createdAt);
            return (
              <tr key={i}>
                <td>{explorePost.title}</td>
                <td>
                  {("0" + createdDate.getMonth()).slice(-2)}/{("0" + createdDate.getDate()).slice(-2)}/{createdDate.getFullYear()}
                </td>
                <td>
                  <VisibilityToggle id={explorePost.id} dataType={"article"} initialValue={explorePost.visible} />
                </td>
                <td className={""}>
                  <Link to={`${path}/${explorePost.id}`} className={"button is-small"}>Edit</Link>{" "}
                  <DeleteButton id={explorePost.id} dataType={"article"} cb={() => fetchPosts(currentPage)} />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <nav className="pagination" role="navigation" aria-label="pagination">
        <button
          className="button is-small pagination-previous"
          title="This is the first page"
          disabled={currentPage === 0}
          onClick={async () => {
            if (currentPage <= 0) return;
            await fetchPosts(currentPage - 1);
            setCurrentPage(currentPage - 1)
          }}
        >Previous</button>
        <button
          className="button is-small pagination-next"
          onClick={async () => {
            if (currentPage === Math.ceil(total / 10) - 1) return;
            await fetchPosts(currentPage + 1);
            setCurrentPage(currentPage + 1)
          }}
          disabled={currentPage === Math.ceil(total / 10) - 1}
        >Next page</button>
        <ul className="pagination-list">
          {generatePages()}
        </ul>
      </nav>
    </>
  );
}

export default ArticleTableView;