import React, { useContext, useEffect, useState } from 'react';
import baseURL from "./util/base-url";
import { SneakerSeekerAdminAPI } from "./util/sssdk";
// import tok from "./util/token";
// Stylesheets for Dashboard
import 'bulma/css/bulma.min.css';
import './assets/styles/main.scss';
import Dashboard from './pages/Dashboard';
import { useRouteMatch } from 'react-router-dom';
import { UserContext } from './stores/UserContext';

export let api: SneakerSeekerAdminAPI | null = null;

function App() {
  const { token, setToken } = useContext(UserContext);
  // const [token, setToken] = useState<string>(tok);
  const route = window.location.pathname;

  useEffect(() => {
    console.log(process.env.NODE_ENV)
    const tok = localStorage.getItem("user_access_token");
    if (tok === "" || !tok) {
      // Check the route
      if (route === "/oauth-redirect-handler") return;
      let popup = window.open(
        `${baseURL}/auth/discord/admin`,
        "handleLogin",
        "toolbar=no, menubar=no, width=600, height=700, top=100, left=100"
      );

      // Adds an event listener to get the token back from the popup
      window.addEventListener('message', message => {
        console.log(message.data)
        if (message.source !== popup) return;

        const tokenFromOAuth = message.data.replace('?token=', '');
        localStorage.setItem("user_access_token", tokenFromOAuth);
        window.location.href = "/";
      });

      if (!popup) return;

      // Brings the window to the user's attention
      popup.focus();
    } else {
        setToken(tok);
        api = new SneakerSeekerAdminAPI(tok);
    }
  }, []);

  if (token === "" || !token) {
    if (route !== "/oauth-redirect-handler") return <p>Please Login</p>;
  }
  return (
    <>
      <Dashboard />
    </>
  );
}

export default App;
