import React, { useEffect } from 'react';
import {
  Switch,
  Route,
  Link,
  useRouteMatch
} from "react-router-dom";
import ExploreTableView from '../components/explore/ExploreTableView';
import CreateExplorePostForm from '../components/explore/CreateExplorePostForm';
import EditExplorePostForm from '../components/explore/EditExplorePostForm';

const Explore: React.FC<{}> = ({}) => {
  let { path, isExact } = useRouteMatch();

  return (
    <div className={"page"}>
      <section className={"section"}>
        <div className="level">
          <div className="level-left">
            <h2 className="title is-2">Explore Manager</h2>
          </div>
          <div className="level-right">
            {isExact
              ? <Link to={`${path}/create`}>Create</Link>
              : <Link to={"/explore"}>Go Back</Link>
            }
          </div>
        </div>
        <hr/>
        <Switch>
          <Route exact path={path}>
            <ExploreTableView />
          </Route>
          <Route exact path={`${path}/create`}>
            <CreateExplorePostForm />
          </Route>
          <Route
            path={`${path}/:id`}
            children={(props) => <EditExplorePostForm id={props.match?.params?.id}/>}
          />
        </Switch>
      </section>
    </div>
  );
}

export default Explore;