import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../stores/UserContext';
import { SneakerSeekerAdminAPI } from '../util/sssdk';
import DeleteButton from '../components/DeleteButton';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import * as yup from "yup";
import Retailer from '../types/Retailer';

interface ICreateExploreInputs {
  name: string;
  image: FileList;
}

const SUPPORTED_FORMATS = [
  "image/jpeg",
  "image/png"
];

const schema = yup.object().shape({
  name: yup.string().required(),
  image: yup.mixed().required().test(
    "fileFormat",
    "Unsupported Format (.jpeg and .png only)",
    value => value[0] && SUPPORTED_FORMATS.includes(value[0].type)
  )
})

const Retailers: React.FC<{}> = ({}) => {
  const { token } = useContext(UserContext);
  const api = new SneakerSeekerAdminAPI(token);
  const [retailers, setRetailers] = useState<Retailer[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [retailerValue, setRetailerValue] = useState<string>("");
  const [fileName, setFileName] = useState("Select an Image to Upload.");

  const { register, handleSubmit, errors, setValue } = useForm<ICreateExploreInputs>({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    (async () => {
      await fetchRetailers();
    })();
  }, []);

  const fetchRetailers = async () => {
    try {
      setRetailers(await api.getAllRetailers());
    } catch (error) {
      console.log(error);
    }
  }

  const createRetailer = async ({ name, image }: ICreateExploreInputs) => {
    setLoading(true);
    try {
      const success = await api.createRetailer(name, image[0]);

      if (success) {
        setRetailerValue("");
        setFileName("Select an Image to Upload.");
        await fetchRetailers();
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  //@ts-ignore
  let imageErrors = errors.image?.message;

  return (
    <div className={"page"}>
      <section className={"section"}>
        <div className="level">
          <div className="level-left">
            <h2 className="title is-2">Retailers Manager</h2>
          </div>
          <div className="level-right" />
        </div>
        <hr/>
        <table className="table is-fullwidth">
          <thead>
            <tr>
              <td colSpan={3}>
                <form onSubmit={handleSubmit(createRetailer)}>
                  <div className="control" style={{ width: "100%"}}>
                    <input
                      className="input"
                      type="text"
                      placeholder="Retailer Name"
                      name="name"
                      ref={register}
                    />
                  </div>
                  <br />
                  <div className="control">
                    <div className="file is-boxed has-name">
                      <label className="file-label">
                        <input className="file-input" type="file" name="image" accept="image/png, image/jpeg"
                         ref={register}
                        onChange={e => {
                          const fileName = e.target.value.split('\\').pop();
                          setFileName(fileName!);
                        }}
                        />
                        <span className="file-cta">
                          <span className="file-label">
                            Image Upload
                          </span>
                        </span>
                        <span className="file-name">
                          {fileName}
                        </span>
                      </label>
                    </div>
                    <p className="help is-danger">{imageErrors}</p>
                  </div>
                  <br />
                  <button
                    className={`button extend ${loading ? 'is-loading' : ''}`}
                    disabled={loading}
                  >Create</button>
                </form>
              </td>
            </tr>
            <tr>
              <th>Logo</th>
              <th>Name</th>
              <th/>
            </tr>
          </thead>
          <tbody>
          {retailers.map(retailer => (
            <tr>
              <td><img src={retailer.image} style={{ width: "80px" }}/></td>
              <td>{retailer.name}</td>
              <td style={{ textAlign: "right" }}>
                <DeleteButton id={retailer.id} dataType={"retailer"} cb={fetchRetailers} />
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </section>
    </div>
  );
}

export default Retailers;